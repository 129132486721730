// js/index-ImagesImport.js

// photos for reviews
import avatar from '../images/Avatar.png';
import dominikPhoto from '../images/reviews-Dominik.webp';
import marekPhoto from '../images/reviews-Marek.webp';

const images = {
  dominikPhoto,
  marekPhoto,
  avatar,
};

export default images;
